import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="redirect-with-confirmation"
export default class extends Controller {
  static values = {
    text: String,
    url: String,
  }

  connect() {
    console.log("this.textValue", this.textValue)
    console.log("this.urlValue", this.urlValue)

    if (window.confirm(this.textValue)) {
      Turbo.visit(this.urlValue)
    }
  }
}
