import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="tom-select"
export default class extends Controller {
  static values = {
    create: { type: Boolean, default: false },
  }

  connect() {
    this.select = new TomSelect(this.element, this.options)
  }

  disconnect() {
    this.destroy()
  }

  clear() {
    this.select.clear()
  }

  destroy() {
    this.select.destroy()
  }

  get options() {
    return {
      create: this.createValue,
      createOnBlur: this.createValue,
    }
  }
}
