document.addEventListener("turbo:load", () => {
  monitorTurboFrames()
})

document.addEventListener("oxotags:init:monitorTurboFrames", () => {
  monitorTurboFrames()
})

function monitorTurboFrames() {
  // Durch alle Turbo-Frames gehen und denen, die einen Spinner anzeigen sollen, einen Spinner hinzufügen
  // (wenn das Frame gerade am laden ist) https://turbo.hotwired.dev/reference/attributes#automatically-added-attributes
  const turboFramesWithLoadingSpinner = document.querySelectorAll("turbo-frame[data-loading-spinner='true']")
  turboFramesWithLoadingSpinner.forEach((frame) => monitorTurboFrameAriaBusyAttribute(frame))
}

function monitorTurboFrameAriaBusyAttribute(frame) {
  // Wen das Frame gerade am laden ist, einen Spinner hinzufügen (wird z.B. bei Frames mit einem src-Attribut passieren)
  if (frame.hasAttribute("aria-busy")) {
    addSpinnerToElement(frame)
  }

  // Wenn das frame zu einem späteren Zeitpunkt das Attribute aria-busy bekommt, einen Spinner hinzufügen
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.attributeName === "aria-busy" && frame.getAttribute("aria-busy") === "true") {
        addSpinnerToElement(frame)
      }
    })
  })

  observer.observe(frame, { attributes: true })
}

function addSpinnerToElement(element) {
  let extraClasses = ""
  if (element.hasAttribute("data-loading-class")) {
    extraClasses = element.getAttribute("data-loading-class")
  }
  element.innerHTML = `
    <div class='d-flex justify-content-center align-content-center p-3 ${extraClasses}'>
      <span class="loader-spinner"><span class='visually-hidden'>Loading...</span></span>
    </div>
  `
}

export { addSpinnerToElement }
