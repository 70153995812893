import { StreamActions } from "@hotwired/turbo"
import { toast } from "../utils/toast"

// Gute Anleitungen für StreamActions:
// https://marcoroth.dev/posts/guide-to-custom-turbo-stream-actions
// https://www.youtube.com/watch?v=OSiGSUo_Jx8

// Für viele StreamActions gibt es bereits Beispiele in der TurboPower library:
// https://github.com/marcoroth/turbo_power

StreamActions.dispatch_js_event = function () {
  const eventName = this.getAttribute("event_name")
  const event = new Event(eventName)
  window.dispatchEvent(event)
}

StreamActions.toast = function () {
  const type = this.getAttribute("type")
  const message = this.getAttribute("message")
  toast(message, type)
}
