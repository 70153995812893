import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-into-view"
export default class extends Controller {
  static values = {
    checkVisibility: { type: Boolean, default: false },
    scollBehavior: { type: String, default: "smooth" },
    autoStart: { type: Boolean, default: true },
  }

  connect() {
    if (this.autoStartValue) {
      this.scrollIntoView()
    }
  }

  async scrollIntoView() {
    if (this.checkVisibilityValue) {
      // Prüfen, ob das letzte Element vor diesem Element sichtbar ist
      const containerSelector = this.element.dataset.containerSelector
      const children = document.querySelector(containerSelector).children
      const lastChildBeforeThisElement = children[children.length - 2]

      const lastChildIsVisible = await this.elementIsVisible(lastChildBeforeThisElement)

      if (lastChildIsVisible) {
        this.element.scrollIntoView({ behavior: this.scollBehaviorValue })
      }
      return
    }

    this.element.scrollIntoView({ behavior: this.scollBehaviorValue })
  }

  elementIsVisible(domElement) {
    return new Promise((resolve) => {
      const observer = new IntersectionObserver(([entry]) => {
        resolve(entry.intersectionRatio === 1)
        observer.disconnect()
      })
      observer.observe(domElement)
    })
  }
}
