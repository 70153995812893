export class Logger {
  constructor() {
    this.logContainer = document.getElementById("logger-container")
  }

  logToHTML(message, logLevel = "debug") {
    if (!this.logContainer) return

    const time = this.printableTime
    const logEntry = `
      <pre data-log-level="${logLevel}" data-controller="scroll-into-view" data-scroll-into-view-check-visibility-value="true" data-container-selector="#logger-container">
        <span class="device-log-message">${message}</span>
        <span>${time}</span>
      </pre>
    `
    this.logContainer.insertAdjacentHTML("beforeend", logEntry)
  }

  emptyHTMLLog() {
    if (!this.logContainer) return

    this.logContainer.innerHTML = ""
  }

  get printableTime() {
    return new Date().toLocaleTimeString("de-DE", { hour: "2-digit", minute: "2-digit", second: "2-digit" })
  }
}
