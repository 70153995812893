// Diese Bridge-Klasse dient als Schnittstelle zwischen der Turbo Native App und der Web-App.
// Dokumentation:
// https://github.com/hotwired/turbo-android/blob/main/docs/ADVANCED-OPTIONS.md#native---javascript-integration
// https://masilotti.com/turbo-ios/the-javascript-bridge
import { post } from "@rails/request.js"

export default class Bridge {
  // Nachricht von der Turbo Native App empfangen
  static postNativeToJS(action, payload, params = "") {
    switch (action) {
      case "didPressMenu":
        window.dispatchEvent(new CustomEvent("mobileapp:toggle-nav-bar"))
        break
      case "didStartNFCScanning":
        // payload => {}
        break
      case "didStopNFCScanning":
        // payload => {}
        break
      case "didReceiveNFCScanUpdate":
        // payload => {"tagID":"0489A2624F1290"}
        // window.dispatchEvent(new CustomEvent("mobileapp:didReceiveNFCScanUpdate", { detail: { tagID: tagID } }))
        const tagID = JSON.parse(payload)?.tagID
        post("/mobile_app/nfc_scan_update", { body: { deveui: tagID }, responseKind: "turbo-stream" })
        break
      case "didWriteToNFCTag":
        // payload => {"response":[1,0]}
        const response = JSON.parse(payload)?.response
        window.dispatchEvent(new CustomEvent("mobileapp:didWriteToNFCTag", { detail: { response: response } }))
        break
      case "didEnterForeground":
        // payload => {}
        break
      case "log":
        // payload => {"level":"info","message":"some message","error":"null"}
        // Sämtliche Android und iOS Logs
        break
      default:
        console.log("#postNativeToJS unknown action " + action)
    }
  }

  // Nachricht an die Turbo Native App senden
  static postJSToNative(action, payload = {}) {
    // Android
    window.nativeApp?.postMessage(action, JSON.stringify(payload))

    // iOS
    window.webkit?.messageHandlers?.nativeApp?.postMessage({ action: action, payload: payload })
  }
}
