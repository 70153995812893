import { isiOSApp } from "../utils/utils"

export const CHIP_INFO = {
  BUFFER_SIZE: 240,
}

export const EH_MODE = {
  ON_DEMAND: 0x01,
  FORCED_AFTER_BOOT: 0x00,
}

export const SYSTEM_REGISTER = {
  GPO1: 0x00,
  GPO2: 0x01,
  EH_MODE: 0x02,
  RF_MNGT: 0x03,
  RFA1SS: 0x04,
  ENDA1: 0x05,
  RFA2SS: 0x06,
  ENDA2: 0x07,
  RFA3SS: 0x08,
  ENDA3: 0x09,
  RFA4SS: 0x0a,
  FTM: 0x0d,
  LOCK_CFG: 0x0f,
}

export const DYNAMIC_REGISTER = {
  GPO_CTRL_Dyn: 0x00,
  EH_CTRL_Dyn: 0x02,
  MB_CTRL_Dyn: 0x0d,
}

export const COMMAND_CODES = {
  INVENTORY: 0x01,
  STAY_QUIET: 0x02,
  READ_SINGLE_BLOCK: 0x20,
  WRITE_SINGLE_BLOCK: 0x21,
  LOCK_BLOCK: 0x22,
  READ_MULTIPLE_BLOCKS: 0x23,
  WRITE_MULTIPLE_BLOCKS: 0x24,
  SELECT: 0x25,
  RESET_TO_READY: 0x26,
  WRITE_AFI: 0x27,
  LOCK_AFI: 0x28,
  WRITE_DSFID: 0x29,
  LOCK_DSFID: 0x2a,
  GET_SYSTEM_INFO: 0x2b,
  GET_MULTIPLE_BLOCK_SECURITY_STATUS: 0x2c,
  EXTENDED_READ_SINGLE_BLOCK: 0x30,
  EXTENDED_WRITE_SINGLE_BLOCK: 0x31,
  EXTENDED_LOCK_BLOCK: 0x32,
  EXTENDED_READ_MULTIPLE_BLOCKS: 0x33,
  EXTENDED_WRITE_MULTIPLE_BLOCKS: 0x34,
  EXTENDED_GET_SYSTEM_INFO: 0x3b,
  EXTENDED_GET_MULTIPLE_BLOCK_SECURITY_STATUS: 0x3c,
  READ_CONFIGURATION: 0xa0,
  WRITE_CONFIGURATION: 0xa1,
  MANAGE_GPO: 0xa9,
  WRITE_MESSAGE: 0xaa,
  READ_MESSAGE_LENGTH: 0xab,
  READ_MESSAGE: 0xac,
  READ_DYNAMIC_CONFIGURATION: 0xad,
  WRITE_DYNAMIC_CONFIGURATION: 0xae,
  WRITE_PASSWORD: 0xb1,
  PRESENT_PASSWORD: 0xb3,
  FAST_READ_SINGLE_BLOCK: 0xc0,
  FAST_READ_MULTIPLE_BLOCKS: 0xc3,
  FAST_READ_DYNAMIC_CONFIGURATION: 0xcd,
  FAST_WRITE_DYNAMIC_CONFIGURATION: 0xce,
  FAST_EXTENDED_READ_SINGLE_BLOCK: 0xc4,
  FAST_EXTENDED_READ_MULTIPLE_BLOCKS: 0xc5,
  FAST_WRITE_MESSAGE: 0xca,
  FAST_READ_MESSAGE_LENGTH: 0xcb,
  FAST_READ_MESSAGE: 0xcc,
}

// Technische Kommunikations-Implementation des ST25DV Chips
// https://www.st.com/en/nfc/st25dv-i2c-series-dynamic-nfc-tags.html
export default class ST25DV {
  sendNFCMessage(commandCode, data = []) {
    const REQUEST_FLAG = 0x02 // Fast transfer mode
    const IC_MFG_CODE = 0x02 // ST Microelectronics

    // Bei iOS setzen wir Request Flag und IC MFG Code manuell (wir haben kein Wahl, Apple schreibt uns das vor)
    const bytes = isiOSApp ? [commandCode, ...data] : [REQUEST_FLAG, commandCode, IC_MFG_CODE, ...data]

    window.bridge.postJSToNative("writeToNFCTag", { message: bytes })
  }

  readSystemConfig(addr) {
    this.sendNFCMessage(COMMAND_CODES.READ_CONFIGURATION, [addr])
  }

  writeSystemConfig(addr, data) {
    this.sendNFCMessage(COMMAND_CODES.WRITE_CONFIGURATION, [addr, data])
  }

  readDynamicConfig(addr) {
    this.sendNFCMessage(COMMAND_CODES.READ_DYNAMIC_CONFIGURATION, [addr])
  }

  writeDynamicConfig(addr, data) {
    this.sendNFCMessage(COMMAND_CODES.WRITE_DYNAMIC_CONFIGURATION, [addr, data])
  }

  presentPassword(pwNumber, password) {
    this.sendNFCMessage(COMMAND_CODES.PRESENT_PASSWORD, [pwNumber, ...password])
  }

  getEHMode() {
    this.readSystemConfig(SYSTEM_REGISTER.EH_MODE)
  }

  setEHMode(mode) {
    this.writeSystemConfig(SYSTEM_REGISTER.EH_MODE, mode)
  }

  enableEH() {
    this.writeDynamicConfig(DYNAMIC_REGISTER.EH_CTRL_Dyn, 0x01)
  }

  disableEH() {
    this.writeDynamicConfig(DYNAMIC_REGISTER.EH_CTRL_Dyn, 0x00)
  }

  configureGPO(conf) {
    this.writeSystemConfig(SYSTEM_REGISTER.GPO1, conf)
  }

  readMailboxMessageLength() {
    this.sendNFCMessage(COMMAND_CODES.READ_MESSAGE_LENGTH)
  }

  readMailboxMessage() {
    const pointer = 0x00 // If pointer and length = 0 => read all the message bytes
    const length = 0x00
    this.sendNFCMessage(COMMAND_CODES.READ_MESSAGE, [pointer, length])
  }

  writeMailboxMessage(byteArray) {
    this.sendNFCMessage(COMMAND_CODES.WRITE_MESSAGE, [byteArray.length - 1, ...byteArray])
  }

  // NFCHandler functions
  isError(chipResponse) {
    return chipResponse[0] != 0
  }

  isReady(chipResponse) {
    return chipResponse[1] & 0x01
  }

  isMailboxWrittenByHost(chipResponse) {
    // Mit Host meinen wir die Hardware.
    // Firmware auf der Hardware schreibt z.B. in die Mailbox wenn der screen aktualisiert wurde
    return chipResponse[1] & 0x02
  }

  canReceiveMessage(chipResponse) {
    return chipResponse[1] & 0x04
  }

  bufferSize() {
    return CHIP_INFO.BUFFER_SIZE
  }
}
