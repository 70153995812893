import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toast-handler"
export default class extends Controller {
  connect() {
    this.toast = bootstrap.Toast.getOrCreateInstance(this.element, this.toastOptions)
    this.toast.show()
  }

  disconnect() {
    this.toast.dispose()
  }

  beforeTurboCache() {
    this.element.remove()
  }

  get toastOptions() {
    return {
      animation: false,
      autohide: true,
      delay: 2000,
    }
  }
}
