/* ---------------------------------- General --------------------------------- */
export const resize = (fn) => window.addEventListener("resize", fn)
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/* -------------------------------- LocalStore --------------------------------- */
export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return JSON.parse(store.getItem(key)) || defaultValue
  } catch {
    return store.getItem(key) || defaultValue
  }
}

export const setItemToStore = (key, payload, store = localStorage) => store.setItem(key, payload)
export const getStoreSpace = (store = localStorage) => parseFloat((escape(encodeURIComponent(JSON.stringify(store))).length / (1024 * 1024)).toFixed(2))

/* ---------------------------------- Cookies --------------------------------- */
export const setCookie = (name, value, days = 400) => {
  // 400 Tage ist die empfohlene maximale Lebensdauer für Cookies
  let expires = ""
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000) // days * hours * minutes * seconds * milliseconds
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`
}

export const getCookie = (name) => {
  name = `${name}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookie.split(";")

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i]
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }

  return null
}

const { userAgent } = window.navigator
export const isiOSApp = /Turbo Native iOS/.test(userAgent)
export const isAndroidApp = /Turbo Native Android/.test(userAgent)
export const isMobileApp = isiOSApp || isAndroidApp
export const mobileAppPlatform = isMobileApp ? (isiOSApp ? "ios" : "android") : null
export const mobileAppVersion = isMobileApp ? (userAgent.match(/app_version: ([^;]+)/) || [])[1] : null // ([^;]+) => all characters except semicolon
export const mobileAppUniqueDeviceId = isMobileApp ? (userAgent.match(/unique_device_id: ([^;]+)/) || [])[1] : null // ([^;]+) => all characters except semicolon

export default {
  sleep,
  resize,
  getItemFromStore,
  setItemToStore,
  getStoreSpace,
  setCookie,
  getCookie,
  isiOSApp,
  isAndroidApp,
  isMobileApp,
  mobileAppPlatform,
  mobileAppVersion,
  mobileAppUniqueDeviceId,
}
