export const renderIcon = (container, type = "success") => {
  container.innerHTML = type === "success" ? sucessIconHTML() : errorIconHTML()
  const animationElement = container.querySelector(".sweetalert-icon")
  animationElement.style.display = "flex"
}

const sucessIconHTML = () => {
  return `
    <div class="sweetalert-success-animation sweetalert-icon" style="display: none;">
      <svg class="circle-svg">
        <circle cx="50" cy="50" r="45" fill="none" stroke="#4CAF50" stroke-width="5"></circle>
      </svg>
      <svg class="checkmark-svg">
        <polyline points="25,50 40,65 75,35" fill="none" stroke="#4CAF50" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></polyline>
      </svg>
    </div>
  `
}

const errorIconHTML = () => {
  return `
    <div class="sweetalert-error-animation sweetalert-icon" style="display: none;">
      <svg class="circle-svg">
        <circle cx="50" cy="50" r="45" fill="none" stroke="#F44336" stroke-width="5"></circle>
      </svg>
      <svg class="x-mark-svg">
        <line class="first-line" x1="30" y1="30" x2="70" y2="70" stroke="#F44336" stroke-width="5" stroke-linecap="round"></line>
        <line class="second-line" x1="70" y1="30" x2="30" y2="70" stroke="#F44336" stroke-width="5" stroke-linecap="round"></line>
      </svg>
    </div>
  `
}

export default renderIcon
