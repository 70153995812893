import { Controller } from "@hotwired/stimulus"

// Dies ist unser Bridge Controller, mit dem wir Nachrichten an die Turbo Native App senden können.
// Wichtig: Dies hat nichts mit der Strada Bridge zu tun.

// Connects to data-controller="native--bridge"
export default class extends Controller {
  static values = {
    action: String,
    payload: Object,
    autoSend: { type: Boolean, default: false },
    delayedSend: { type: Boolean, default: false },
    delaySeconds: { type: Number, default: 0 },
  }

  connect() {
    if (this.autoSendValue) {
      this.postJSToNative()
    } else if (this.delayedSendValue) {
      setTimeout(() => {
        this.postJSToNative()
      }, this.delaySecondsValue * 1000)
    }
  }

  // Generische Möglichkeit eine Nachricht an die Turbo Native App zu senden
  postJSToNative() {
    window.bridge.postJSToNative(this.actionValue, this.payloadValue)
  }
}
