import ST25DV, { DYNAMIC_REGISTER } from "../models/ST25DV.js"

export default class NfcHandler {
  constructor() {
    this.nfcImplementation = new ST25DV()
  }

  askForReadiness() {
    // Chip fragen ob FTM und MB aktiviert sind
    return this.nfcImplementation.readDynamicConfig(DYNAMIC_REGISTER.MB_CTRL_Dyn)
  }

  writeNFCMailboxMessage(data) {
    return this.nfcImplementation.writeMailboxMessage(data)
  }

  isError(chipResponse) {
    return this.nfcImplementation.isError(chipResponse)
  }

  isReady(chipResponse) {
    return this.nfcImplementation.isReady(chipResponse)
  }

  isMailboxWrittenByHost(chipResponse) {
    return this.nfcImplementation.isMailboxWrittenByHost(chipResponse)
  }

  canReceiveMessage(chipResponse) {
    return this.nfcImplementation.canReceiveMessage(chipResponse)
  }

  bufferSize() {
    return this.nfcImplementation.bufferSize()
  }
}
