import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="global-hotkey"
export default class extends Controller {
  static targets = ["searchForm", "searchInput"]

  focusSearchInput() {
    if (this.hasSearchInputTarget) {
      document.querySelector('[data-bs-toggle="dropdown"]')?.click()
      this.searchInputTarget.focus()
    }
  }
}
