import { Controller } from "@hotwired/stimulus"

import { compress } from "../../utils/heatshrink.mjs" // Adjust path as necessary
import NfcHandler from "../../models/nfc_handler.js"
import { Logger } from "../../models/logger"
import { sleep, isiOSApp } from "../../utils/utils.js"
import renderIcon from "../../utils/renderIcon"
import { patch } from "@rails/request.js"

const STATE = {
  start: "start",
  wait_for_chip_readiness: "wait_for_chip_readiness",
  wait_for_screen_update: "wait_for_screen_update",
  error: "error",
  finish: "finish",
}

const TIME_TILL_ERROR = 7500

// Connects to data-controller="nfc"
export default class extends Controller {
  static targets = ["imageData", "progressTitle", "progressBar", "iconContainer"]
  static values = {
    autoStart: { type: Boolean, default: false },
    state: { type: String, default: "start" },
    progressBar: { type: Number, default: 1 },
    deveui: String,
  }

  connect() {
    this.bindedWriteToNFCTagCallback = this.writeToNFCTagCallback.bind(this)
    window.addEventListener("mobileapp:didWriteToNFCTag", this.bindedWriteToNFCTagCallback)

    this.nfcHandler = new NfcHandler()
    this.logger = new Logger()
    this.initState()

    if (this.autoStartValue) {
      this.start()
    }
  }

  disconnect() {
    window.removeEventListener("mobileapp:didWriteToNFCTag", this.bindedWriteToNFCTagCallback)
  }

  stateValueChanged() {
    // Fehler darstellen nach TIME_TILL_ERROR
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.stateValue = STATE.error
    }, TIME_TILL_ERROR)

    // Aktueller Status anzeigen
    this.progressTitleTarget.innerHTML = I18n[this.stateValue]

    // Progress Bar aktualisieren
    switch (this.stateValue) {
      case STATE.start:
        break
      case STATE.wait_for_chip_readiness:
        this.progressBarValue += 1
        break
      case STATE.wait_for_screen_update:
        this.progressBarValue += 1
        break
      case STATE.error:
        renderIcon(this.iconContainerTarget, "error")
        if (this.hasDeveuiValue) {
          patch(`/mobile_app/nfc_scan_update/${this.deveuiValue}`, { body: { success: false }, responseKind: "turbo-stream" })
        }
        this._mobileAppResponse()
        break
      case STATE.finish:
        // Erfolg darstellen
        this.progressBarValue += 1
        renderIcon(this.iconContainerTarget, "success")
        this._mobileAppResponse()

        // Server informieren
        clearTimeout(this.timeout)
        if (this.hasDeveuiValue) {
          patch(`/mobile_app/nfc_scan_update/${this.deveuiValue}`, { body: { success: true }, responseKind: "turbo-stream" })
        }

        // Seite aktualisieren
        setTimeout(() => {
          Turbo.session.refresh(location.href)
        }, 1000)
        break
    }
  }

  progressBarValueChanged() {
    // Update Progress Bar
    if (this.progressBarValue > this.progressBarCircles.length) this.progressBarValue = this.progressBarCircles.length

    this.progressBarCircles.forEach((circle, index) => {
      if (index < this.progressBarValue) circle.classList.add("active")
      else circle.classList.remove("active")
    })
    const actives = document.querySelectorAll(".active")
    this.progressBarLine.style.width = ((actives.length - 1) / (this.progressBarCircles.length - 1)) * 100 + "%"
  }

  initState() {
    this.bufferCount = 0
    this.chunkBuffer = Array(this.bufferSize)
    this.compressedImageContent = null

    this.logger.emptyHTMLLog()
  }

  start() {
    this.initState()
    this.logger.logToHTML(`Start NFC Transfer`)
    this.nfcHandler.askForReadiness()
    this.stateValue = STATE.start
  }

  writeToNFCTagCallback(event) {
    const lastChipResponse = event.detail?.response
    if (this.remainingBytes <= 0) {
      this.logger.logToHTML(`All bytes are sent!`)

      if (this.nfcHandler.isMailboxWrittenByHost(lastChipResponse)) {
        this.stateValue = STATE.finish
      } else {
        this.logger.logToHTML(`Wait until Screen will be updated`)
        this.stateValue = STATE.wait_for_screen_update
        sleep(200).then(() => this.nfcHandler.askForReadiness())
      }
      return
    }

    if (this.nfcHandler.isError(lastChipResponse)) {
      this.logger.logToHTML(`Chip has an error (${lastChipResponse})`, "error")
      this.stateValue = STATE.error
      return
    }

    // Warten bis der Chip bereit für eine Übertragung ist
    if (!this.nfcHandler.isReady(lastChipResponse)) {
      this.logger.logToHTML(`Wait until chip is ready`)
      this.stateValue = STATE.wait_for_chip_readiness
      sleep(200).then(() => this.nfcHandler.askForReadiness())
      return
    }

    // Warten bis die Message Box leer ist
    // if (!this.nfcHandler.canReceiveMessage(lastChipResponse)) {
    //   this.logger.logToHTML(`messageBox is not empty!`)
    //   sleep(200).then(() => this.nfcHandler.askForReadiness())
    //   return
    // }

    // Bild übertragen
    this.logger.logToHTML(`Remaining Chunks ${this.remainingFullChunks}`)
    if (this.remainingFullChunks >= 1) {
      this._sendFullChunks()
      return
    }

    if (this.remainingBytes > 0) {
      this._sendRemainingBytes()
    }
  }

  // Teilt das Bild in (this.bufferSize) Byte große Chunks auf und sendet diese an die Native App
  _sendFullChunks() {
    for (let counter = 0; counter < this.bufferSize; counter++) {
      this.chunkBuffer[counter] = this.compressedImage[this.bufferCount * this.bufferSize + counter]
    }

    this.bufferCount++
    this.logger.logToHTML(`send fullchunk`)
    this.nfcHandler.writeNFCMailboxMessage(this.chunkBuffer)
  }

  // Sendet die verbleibenden Bytes an die Native App
  _sendRemainingBytes() {
    const remainingData = Array(this.remainingBytes)
    for (let counter = 0; counter < this.remainingBytes; counter++) {
      remainingData[counter] = this.compressedImage[this.bufferCount * this.bufferSize + counter]
    }

    this.bufferCount += Math.ceil(this.remainingBytes / this.bufferSize)
    this.logger.logToHTML(`send remaining bytes`)
    this.nfcHandler.writeNFCMailboxMessage(remainingData)
  }

  _mobileAppResponse() {
    if (this.stateValue === STATE.finish && isiOSApp) {
      // iOS macht automatisch ein Vibration Feedback
      // Wir müssen allerdings den NFC Scan stoppen, um das PopUp zu schliessen
      window.bridge.postJSToNative("stopNFCScan")
    }
    if (this.stateValue === STATE.finish && !isiOSApp) {
      window.bridge.postJSToNative("vibrate", { pattern: [0, 180, 50, 180] })
    }

    if (this.stateValue === STATE.error) {
      window.bridge.postJSToNative("stopNFCScan", { errorMessage: I18n.error_while_transferring_data })
    }
  }

  get imageBytes() {
    const byteString = this.imageDataTarget.dataset.canvasBinaryOutputValue
    return byteString.split(",").map((item) => item.trim())
  }

  get imageBytesUint8() {
    const bytes = this.imageBytes
    const data = new Uint8Array(bytes.length)
    for (let counter = 0; counter < bytes.length; counter++) {
      data[counter] = bytes[counter]
    }

    return data
  }

  get compressedImage() {
    if (this.compressedImageContent) return this.compressedImageContent

    this.compressedImageContent = compress(this.imageBytesUint8)
    return this.compressedImageContent
  }

  get remainingFullChunks() {
    return this.remainingBytes / this.bufferSize
  }

  get remainingBytes() {
    return this.compressedImage.length - this.bufferCount * this.bufferSize
  }

  get bufferSize() {
    return this.nfcHandler.bufferSize()
  }

  get progressBarCircles() {
    return this.progressBarTarget.querySelectorAll(".circle")
  }

  get progressBarLine() {
    return this.progressBarTarget.querySelector(".line")
  }
}
