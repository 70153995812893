import "@hotwired/turbo-rails"
import TurboPower from "turbo_power"
TurboPower.initialize(Turbo.StreamActions)

import "./cache_control"
import "./frame_control"
import "./custom_stream_actions"

import Bridge from "./bridge.js"
window.bridge = Bridge
