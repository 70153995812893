class Toast {
  constructor(message, type) {
    this.message = message
    this.type = type
  }

  toast() {
    document.getElementById("flash-messages").insertAdjacentHTML("beforeend", this.toastHTML)
  }

  get toastHTML() {
    return `
      <div id="toast-${new Date().getTime()}" class="toast align-items-center text-bg-${
      this.classForFlash
    } border-0" role="alert" aria-live="assertive" aria-atomic="true" data-controller="toast-handler" data-turbo-permanent>
        <div class="d-flex">
          <div class="toast-body w-100">
            <div class="d-flex align-items-center">
              ${this.type === "success" || this.type === "notice" ? '<i class="fa-solid fa-square-check me-2"></i>' : ""}
              <div class="d-flex justify-content-center w-100">
                ${this.message}
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }
  get classForFlash() {
    return (
      {
        success: "success",
        notice: "success",
        error: "danger",
        alert: "danger",
      }[this.type] || this.type
    )
  }
}

export function toast(message, type = "notice") {
  new Toast(message, type).toast()
}
