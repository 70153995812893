import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

// Connects to data-controller="native--nav-bar"
export default class extends Controller {
  static targets = ["navBar"]

  connect() {
    this.navBarSize = "70vw"

    // Standardwerte (Beim navigieren wird `display = "none"` gesetzt. Hier setzen wir es wieder auf "block")
    this.navBar.style.width = "0"
    this.navBar.style.display = "block"

    // Wenn ausserhalb der Nav-Bar geklickt wird, wird die Nav-Bar geschlossen
    useClickOutside(this, { element: this.navBar })
  }

  openLink(event) {
    this.navBar.style.display = "none"

    // Wenn der Link zu früh aufgerufen wird, ist die Nav-Bar noch nicht geschlossen wenn MobileApp ein screenshot macht.
    // Dies führt beim zurücknavigieren zu einem "flackern".
    // Hack: Durch das setTimeout sicherstellen, dass die Nav-Bar geschlossen ist, bevor der Link aufgerufen wird.
    setTimeout(() => {
      Turbo.visit(event.target.closest(".nav-link").dataset.href)
    }, 40)
  }

  toggle() {
    if (this.navBar.style.width === this.navBarSize) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.navBar.style.width = this.navBarSize
  }

  close() {
    this.navBar.style.width = "0"
  }

  clickOutside(event) {
    event.preventDefault()
    event.stopPropagation()

    this.close()
  }

  get navBar() {
    return this.navBarTarget
  }
}
