import { Controller } from "@hotwired/stimulus"
import rasterizeHTML from "rasterizehtml"
import { sleep } from "../../utils/utils"

// Connects to data-controller="canvas"
export default class extends Controller {
  static outlets = ["editor"]
  static targets = ["canvas", "html", "stylesheet"]
  static values = {
    canvasHeight: { type: Number, default: 264 },
    canvasWidth: { type: Number, default: 176 },
    colorThreshold: { type: Number, default: 867 },
    binaryOutput: String,
  }

  connect() {
    this.ctx = this.canvasTarget.getContext("2d")
    this.draw()
  }

  draw() {
    this.clearCanvas()
    sleep(200).then(() => {
      this.canvasTarget.width = this.canvasWidthValue
      this.canvasTarget.height = this.canvasHeightValue

      this.convertHTMLToCanvas()

      sleep(1000).then(() => {
        this.dispatch("drawn")
      })
    })
  }

  adoptChangesFromEditor() {
    if (!this.hasEditorOutlet) {
      console.error("No editor outlet found")
      return
    }

    this.textValue = this.editorOutlet.htmlTarget.value
    this.draw()
  }

  clearCanvas() {
    this.ctx.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)
  }

  convertHTMLToCanvas() {
    const fakeDocument = document.implementation.createHTMLDocument()
    fakeDocument.body.appendChild(this.htmlContent)

    rasterizeHTML.drawDocument(fakeDocument, this.canvasTarget).then(() => {
      this.binaryOutputValue = this.canvasPixelsAsHexArray.join(", ")
    })
  }

  get htmlContent() {
    if (this.hasEditorOutlet) {
      const tempDiv = document.createElement("div")
      tempDiv.innerHTML = this.editorOutlet.htmlTarget.innerText.trim()
      tempDiv.classList.add("screen-content-for-canvas")
      const stylesheetElement = this.stylesheetTarget.cloneNode(true)
      tempDiv.appendChild(stylesheetElement)
      return tempDiv
    } else {
      return this.htmlTarget.cloneNode(true)
    }
  }

  get canvasPixelsAsHexArray() {
    return Array.from(this.canvasPixelsAsByteArray).map((byte) => `0x${byte.toString(16).padStart(2, "0")}`)
  }

  get canvasPixelsAsByteArray() {
    const imageData = this.ctx.getImageData(0, 0, this.canvasTarget.width, this.canvasTarget.height)
    const bytes = imageData.data
    const blackWhitePixelBitArray = []
    const byteArray = []

    const isLandscape = this.canvasWidthValue > this.canvasHeightValue
    const width = this.canvasTarget.width
    const height = this.canvasTarget.height

    // getImageData liefert RGBA-Werte zurück (4 Bytes pro Pixel)
    // Wir sind aber nur in Schwarz und Weiss interessiert
    for (let i = 0; i < bytes.length; i += 4) {
      const r = bytes[i] // Rot
      const g = bytes[i + 1] // Grün
      const b = bytes[i + 2] // Blau
      const a = bytes[i + 3] // Alpha

      const totalColor = r + g + b + a
      const isWhite = totalColor < this.colorThresholdValue
      if (isWhite) {
        blackWhitePixelBitArray.push(0)
      } else {
        blackWhitePixelBitArray.push(1)
      }
    }

    // Im Landscape-Modus müssen wir die Pixel um 90 Grad drehen
    // (Da die Hardware beim Pixel-Array erwartet, dass wir oben links anfangen - aus Sicht vom Portrait-Modus)
    if (isLandscape) {
      const rotatedPixelArray = []
      for (let x = 0; x < width; x++) {
        for (let y = height - 1; y >= 0; y--) {
          rotatedPixelArray.push(blackWhitePixelBitArray[y * width + x])
        }
      }
      blackWhitePixelBitArray.length = 0
      blackWhitePixelBitArray.push(...rotatedPixelArray)
    }

    // Wir speichern die Pixel als Bytes (8 Pixel pro Byte)
    for (let i = 0; i < blackWhitePixelBitArray.length; i += 8) {
      const byte = blackWhitePixelBitArray.slice(i, i + 8).join("")
      byteArray.push(parseInt(byte, 2))
    }

    return byteArray
  }
}
