import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bs-modal"
export default class extends Controller {
  static targets = ["modal"]
  static values = {
    autoOpen: { type: Boolean, default: true },
  }

  connect() {
    this.modalElement = this.hasModalTarget ? this.modalTarget : this.element

    this.modal = new bootstrap.Modal(this.modalElement)
    if (this.autoOpenValue) {
      this.openModal()
    }

    // Wenn das Modal geschlossen wird -> Modal entfernen
    this.boundDisposeModal = this.disposeModal.bind(this)
    window.addEventListener("hidden.bs.modal", this.boundDisposeModal)

    this.boundhideModal = this.hideModal.bind(this)
    window.addEventListener("hide-bs-modal", this.boundhideModal)
  }

  disconnect() {
    this.disposeModal()
    window.removeEventListener("hidden.bs.modal", this.boundToggle)
  }

  openModal() {
    this.modal.show()
  }

  hideModal() {
    this.modal.hide()
  }

  disposeModal() {
    // Wenn das Modal noch existiert -> Modal entfernen
    if (this.modal._element) {
      this.modal.dispose()
    }

    // Sicherheitshalber Modal-Backdrop entfernen
    // (Bei "fade" Modals kann es zu Problemen mit dem Backddrop und Scrollen kommen wenn mittels Back-Button zurück navigiert wird)
    document.querySelector("body").classList.remove("modal-open")
    document.querySelector("body").style.overflow = "auto"
    document.querySelector("body").style.paddingRight = "0px"
  }
}
